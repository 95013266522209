import React from 'react';
import PropTypes from 'prop-types';

import img1 from './assets/1.png';
import img2 from './assets/2.png';
import img3 from './assets/3.png';
import img4 from './assets/4.png';
import img5 from './assets/5.png';
import img6 from './assets/6.png';

import styles from './PromotionMoviePaddingtonDecor.styl';
import classNames from 'classnames/bind';


const decors = {
  1: { src: img1 },
  2: { src: img2 },
  3: { src: img3 },
  4: { src: img4 },
  5: { src: img5 },
  6: { src: img6 },
};

const cx = classNames.bind(styles);


const PromotionMoviePaddingtonDecor = ({ className, srcView, ...props }) => {
  return (
    <img
      src={decors[srcView].src}
      className={cx(styles.PromotionMoviePaddingtonDecor, className)}
      alt="decor"
      {...props}
    />
  );
};

export default PromotionMoviePaddingtonDecor;

PromotionMoviePaddingtonDecor.propTypes = {
  className: PropTypes.string,
  srcView: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']).isRequired,
};
